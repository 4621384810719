import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import isEmail from 'validator/es/lib/isEmail';
import isEmpty from 'validator/es/lib/isEmpty';
import { FaPhone, FaRegEnvelope, FaSpinner } from 'react-icons/fa'
import { main, contact } from './contact-us.module.css';
import { internalContainer, resetLink, rotate } from '../styles/utils.module.css';
import Layout from '../components/Layout';
import InternalSection from '../components/InternalSection';
import InternalSectionTitle from '../components/InternalSectionTitle';
import InternalForm from '../components/InternalForm';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import InternalSidebar from '../components/InternalSidebar';
import useMediaQuery from '../hooks/useMediaQuery';

export default () => {
    const { t } = useTranslation();

    const copy = t('contactUs', { returnObjects: true });

    const isDesktop = useMediaQuery('(min-width: 769px)');

    const initialFormFieldValues = {
        firstName: {
            value: '',
            error: null
        },
        lastName: {
            value: '',
            error: null
        },
        emailAddress: {
            value: '',
            error: null
        },
        areaCode: {
            value: '',
            error: null
        },
        phoneNumber: {
            value: '',
            error: null
        },
        messageUs: {
            value: '',
            error: null
        }
    };

    const [state, setState] = useState({
        form: {
            fields: initialFormFieldValues,
            result: null,
            isBeingProcessed: false
        }
    });

    const validate = (fieldName, value) => {
        if (fieldName === 'firstName') {
            if (isEmpty(value, { ignore_whitespace: true })) {
                return {
                    value,
                    error: copy.form.sections.name.field.firstName.errors.empty
                };
            }
        }

        if (fieldName === 'areaCode') {
            if (isEmpty(value, { ignore_whitespace: true })) {
                return {
                    value,
                    error: copy.form.sections.phoneNumber.fields.areaCode.errors.empty
                };
            }
        }

        if (fieldName === 'phoneNumber') {
            if (isEmpty(value, { ignore_whitespace: true })) {
                return {
                    value,
                    error: copy.form.sections.phoneNumber.fields.phoneNumber.errors.empty
                };
            }
        }

        if (fieldName === 'emailAddress') {
            if (!isEmail(value)) {
                return {
                    value,
                    error: copy.form.sections.email.fields.emailAddress.errors.invalidFormat
                };
            }
        }

        if (fieldName === 'messageUs') {
            if (isEmpty(value, { ignore_whitespace: true })) {
                return {
                    value,
                    error: copy.form.sections.messageUs.fields.messageUs.errors.empty
                };
            }
        }

        return {
            value,
            error: null
        };
    }

    const handleChange = (fieldName, value) => {
        setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                fields: {
                    ...prevState.form.fields,
                    [fieldName]: {
                        ...prevState.form.fields[fieldName],
                        value
                    }
                }
            }
        }));
    }

    const contactUs = async (event) => {
        event.preventDefault();

        const newState = { form: { fields: {}, result: null, isBeingProcessed: false } };

        let hasErrors = false;

        for (const fieldName in state.form.fields) {
            const validationResult = validate(fieldName, state.form.fields[fieldName].value);

            if (validationResult.error !== null) {
                hasErrors = true;
            }

            newState.form.fields[fieldName] = validationResult;
        }

        if (!hasErrors) {
            setState(prevState => ({ form: {...prevState.form, isBeingProcessed: true }}));
            try {
                const response = await sendForm({
                    firstName: state.form.fields.firstName.value,
                    lastName: state.form.fields.lastName.value,
                    emailAddress: state.form.fields.emailAddress.value,
                    areaCode: state.form.fields.areaCode.value,
                    phoneNumber: state.form.fields.phoneNumber.value,
                    messageUs: state.form.fields.messageUs.value,
                });

                if (response.status === 200) {
                    newState.form.result = copy.form.result.success;
                    newState.form.fields = initialFormFieldValues;
                } else {
                    throw await response.json();
                }
            } catch(error) {
                newState.form.result = copy.form.result.error;
            }
        }

        setState(newState);
    };

    const sendForm = (data) => {
        const url = `${process.env.GATSBY_API_URL}/v1/contact`;
        return fetch(url, { method: 'POST', body: JSON.stringify(data) });
    };

    const closeDialog = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
        setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                result: null
            }
        }));
    };

    return (
        <Layout>
            <InternalSection content={copy}>
                <div className={`${main} ${internalContainer}`}>
                    <div>
                        <InternalSectionTitle>{copy.mainContent.title}</InternalSectionTitle>
                        <p>{copy.mainContent.paragraphs[0]}</p>
                        <div className={`${resetLink} ${contact}`}>
                            <a
                                data-box
                                href="tel:0421468086"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaPhone size={isDesktop ? '1em' : '1.4em'} style={{ transform: 'scaleX(-1)' }} />
                                <strong>(+61) 421 468 086</strong>
                            </a>
                            <a
                                data-box
                                href="mailto:contact@flashmoves.com.au"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaRegEnvelope size={isDesktop ? '1em' : '1.4em'} />
                                <strong>contact@flashmoves.com.au</strong>
                            </a>
                        </div>
                        <p>{copy.mainContent.paragraphs[1]}</p>
                        <InternalForm>
                            <div data-section>
                                <h4>{copy.form.sections.name.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.name.fields.firstName.label}
                                            id="first-name"
                                            type="text"
                                            value={state.form.fields.firstName.value}
                                            // onFocus={event => event.target.select()}
                                            onChange={event => handleChange('firstName', event.target.value)}
                                        />
                                        {state.form.fields.firstName.error && <small data-error>{state.form.fields.firstName.error}</small>}
                                        <label htmlFor="first-name">{copy.form.sections.name.fields.firstName.label}</label>
                                    </div>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.name.fields.lastName.label}
                                            id="last-name"
                                            type="text"
                                            value={state.form.fields.lastName.value}
                                            onChange={event => handleChange('lastName', event.target.value)}
                                        />
                                        {state.form.fields.lastName.error && <small data-error>{state.form.fields.lastName.error}</small>}
                                        <label htmlFor="last-name">{copy.form.sections.name.fields.lastName.label}</label>
                                    </div>
                                </div>
                            </div>
                            <div data-section>
                                <h4>{copy.form.sections.email.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.email.fields.emailAddress.label}
                                            id="email-address"
                                            type="email"
                                            value={state.form.fields.emailAddress.value}
                                            // onFocus={event => event.target.select()}
                                            onChange={event => handleChange('emailAddress', event.target.value)}
                                        />
                                        {state.form.fields.emailAddress.error && <small data-error>{state.form.fields.emailAddress.error}</small>}
                                        <label htmlFor="email-address">{copy.form.sections.email.fields.emailAddress.label}</label>
                                    </div>
                                </div>
                            </div>
                            <div data-section>
                                <h4>{copy.form.sections.phoneNumber.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.phoneNumber.fields.areaCode.label}
                                            id="area-code"
                                            type="tel"
                                            value={state.form.fields.areaCode.value}
                                            onChange={event => handleChange('areaCode', event.target.value)}
                                        />
                                        {state.form.fields.areaCode.error && <small data-error>{state.form.fields.areaCode.error}</small>}
                                        <label htmlFor="area-code">{copy.form.sections.phoneNumber.fields.areaCode.label}</label>
                                    </div>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.phoneNumber.fields.phoneNumber.label}
                                            id="phone-number"
                                            type="tel"
                                            value={state.form.fields.phoneNumber.value}
                                            onChange={event => handleChange('phoneNumber', event.target.value)}
                                        />
                                        {state.form.fields.phoneNumber.error && <small data-error>{state.form.fields.phoneNumber.error}</small>}
                                        <label htmlFor="phone-number">{copy.form.sections.phoneNumber.fields.phoneNumber.label}</label>
                                    </div>
                                </div>
                            </div>
                            <div data-section>
                                <h4>{copy.form.sections.messageUs.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <textarea
                                            aria-label={copy.form.sections.messageUs.fields.messageUs.label}
                                            id="messageUs"
                                            value={state.form.fields.messageUs.value}
                                            onChange={event => handleChange('messageUs', event.target.value)}
                                        />
                                        {state.form.fields.messageUs.error && <small data-error>{state.form.fields.messageUs.error}</small>}
                                    </div>
                                </div>
                            </div>
                            <Button
                                onClick={async (event) => await contactUs(event)}
                                onKeyPress={async (event)=> await contactUs(event)}
                            >
                                {state.form.isBeingProcessed ? <FaSpinner className={rotate}/> : copy.form.submitButton.label}
                            </Button>
                        </InternalForm>
                    </div>
                    <div>
                        <InternalSidebar />
                    </div>
                </div>
            </InternalSection>
            {state.form.result !== null && <Dialog message={state.form.result} onClose={closeDialog}/>}
        </Layout>
    );
}
